import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonIcon, IonPage } from "@ionic/react";
import { Box } from "@mui/material";
import { UpdateShiftListFn } from "@src/app/dayView/custom-hooks/interfaces";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { DeploymentEnvironmentName, environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useGetPaginatedBookabilityStatusForShifts } from "@src/appV2/OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import { useATeamStatus } from "@src/appV2/Rankings/useATeamStatus";
import { UrgentShift, useUrgentShifts } from "@src/appV2/Shifts/UrgentShifts/api/useUrgentShifts";
import { useGetStreaksSegmentData } from "@src/appV2/Streaks/useGetStreaksSegmentData";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import {
  DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES,
  SEARCH_MODE,
  SHIFT_BOOKED_CONTEXT,
  USER_EVENTS,
} from "@src/constants";
import { HttpStatus } from "@src/constants/http";
import { RadarSdkTestPanel } from "@src/lib/RadarSdkTestPanel/RadarSdkTestPanel";
import { informationCircleOutline, notifications, notificationsOff } from "ionicons/icons";
import { map } from "lodash";
import moment from "moment-timezone";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { FcfShiftData } from "./model";
import ShiftBookingInfoModal from "./shiftBookingInfoModal";
import { UrgentShiftItems } from "./UrgentShiftItems";
import { checkIsNotificationSnoozed, sortUrgentShifts } from "./utils";
import { ShiftFilters } from "../../components/shiftFilters";
import {
  getNotificationPreference,
  setNotificationPreference,
} from "../../privacyPolicy/notificationPreference/api";
import { Response, ResponseCodes } from "../../utils/response";
import ToolTipCard from "../toolTips/toolTipCard";

export function UrgentShifts() {
  const history = useHistory();
  const [showTooltip, setShowTooltip] = useState(false);
  const { showErrorToast } = useToast();

  const [isShiftInfoModalOpen, setIsShiftInfoModalOpen] = useState<boolean>(false);
  const [isNotificationSnoozed, setIsNotificationSnoozed] = useState(false);
  const [isUpdatingSnoozePreference, setIsUpdatingSnoozePreference] = useState(false);
  const [fcfShiftData, setFcfShiftData] = useState<FcfShiftData>();

  const ldFlags = useCbhFlags();
  const location = useLocation();

  const urgentShiftsConfig = ldFlags[CbhFeatureFlag.URGENT_SHIFTS_CONFIG] ?? {};

  const { setFilters } = useWorkerPreferences();
  const worker = useDefinedWorker();
  const { isWorkerAteamEnabled } = useATeamStatus();

  const searchParameters = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const targetShiftId = searchParameters.get("shiftId");
  let openedFrom = searchParameters.get("actionType");

  if (!isDefined(openedFrom)) {
    openedFrom =
      isDefined(targetShiftId) && targetShiftId.length > 0 ? "deep_link" : "internal_link";
  }

  const shiftStart = searchParameters.get("shiftStart");

  // create a callback that can be used to log the event when the
  // shift data is loaded later on
  const logUrgentShiftsPageLoaded = useCallback(
    (shifts: UrgentShift[]) => {
      let maxEarnings = 0;
      let shiftMatchingStart = 0;
      let shiftsIncludeTargetShift = false;

      shifts.forEach((shift) => {
        const pay = shift.pay ?? 0;
        const time = shift.time ?? 0;
        const earnings = pay * time;
        shiftsIncludeTargetShift = shiftsIncludeTargetShift || shift.shiftId === targetShiftId;
        maxEarnings = earnings > maxEarnings ? earnings : maxEarnings;
        if (isDefined(shiftStart) && new Date(shift.start ?? "").toISOString() === shiftStart) {
          shiftMatchingStart += 1;
        }
      });

      const metadata = {
        timestamp: new Date(),
        workerId: worker.userId,
        totalShifts: shifts.length,
        shiftIds: shifts.map((shift) => shift.shiftId).filter((id) => isDefined(id)),
        maxEarnings: Number(maxEarnings.toFixed(2)),
        openedFrom,
      };

      if (isDefined(targetShiftId)) {
        metadata["targetShiftId"] = targetShiftId;
        metadata["shiftsIncludeTargetShift"] = shiftsIncludeTargetShift;
      }

      if (isDefined(shiftStart)) {
        metadata["shiftMatchingStart"] = shiftMatchingStart;
        metadata["shiftStart"] = shiftStart;
      }

      if (isDefined(worker.geoLocation)) {
        [metadata["workerLongitude"], metadata["workerLatitude"]] = worker.geoLocation.coordinates;
      }

      logEvent(USER_EVENTS.URGENT_SHIFTS_PAGE_LOADED, metadata);
    },
    [worker.userId, worker.geoLocation, openedFrom, targetShiftId, shiftStart]
  );

  const distancePreference = worker.preference?.distance ?? DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES;
  const qualificationPreference = worker.preference?.qualification;

  const {
    data: urgentShiftsData,
    isInitialLoading: isUrgentShiftsLoading,
    isSuccess: isUrgentShiftsSuccessful,
    refetch: refetchUrgentShifts,
  } = useUrgentShifts(
    {
      coordinates: worker.geoLocation?.coordinates,
      getCountOnly: false,
    },
    {
      enabled: isDefined(worker.geoLocation?.coordinates),
      meta: {
        userErrorMessage: "Could not load Urgent Shifts! Please Refresh the page again!",
      },
    }
  );
  const searchParams = new URLSearchParams(history.location.search);
  const sortedShifts = isUrgentShiftsSuccessful
    ? sortUrgentShifts(urgentShiftsData.shifts ?? [], { searchParams, isWorkerAteamEnabled })
    : [];

  useEffect(() => {
    if (isUrgentShiftsSuccessful) {
      logUrgentShiftsPageLoaded(urgentShiftsData?.shifts ?? []);
    }
  }, [urgentShiftsData?.shifts, logUrgentShiftsPageLoaded, isUrgentShiftsSuccessful]);

  useLogEffect(USER_EVENTS.VIEWED_URGENT_SHIFTS, {
    hcpUserId: worker.userId,
    hcpName: worker.name,
    timestamp: moment().format(),
    openedFrom,
  });

  const sendBookingFailureEvent = (shift: UrgentShift): void => {
    const hasShiftWithSameStart = map(
      sortedShifts.filter((current) => current._id !== shift._id),
      "start"
    ).includes(shift.start);
    const eventMessage = `User failed to book an urgent shift and ${
      hasShiftWithSameStart ? "" : "no "
    }similar shifts were available`;

    logEvent(USER_EVENTS.URGENT_SHIFT_BOOKING_FAILED, {
      shiftId: shift._id,
      message: eventMessage,
      similarShiftsAvailable: hasShiftWithSameStart,
      context: SHIFT_BOOKED_CONTEXT.URGENT_SHIFTS,
      hcpUserId: worker.userId,
      fcfShiftId: targetShiftId,
    });
  };

  const { shouldEmitSegmentEvent, streaksBonus } = useGetStreaksSegmentData(worker.userId);

  const updateShiftList: UpdateShiftListFn = async (shift, _isInstantBook, response, error) => {
    if (error?.status === HttpStatus.CONFLICT) {
      sendBookingFailureEvent(shift);
      refetchUrgentShifts();
    } else if (response?.isAlreadyGrabbed) {
      showErrorToast(response.message);
      sendBookingFailureEvent(shift as UrgentShift);
      refetchUrgentShifts();
    } else {
      const streaksMetaData = shouldEmitSegmentEvent ? { streaksBonus } : {};
      logEvent(USER_EVENTS.SUCCESSFULLY_BOOKED_URGENT_SHIFT, {
        hcpUserId: worker.userId,
        hcpName: worker.name,
        shiftId: shift._id,
        shiftPay: shift.finalPay,
        facilityName: shift.facility?.name,
        facilityId: shift.facility?._id,
        startAt: shift.start,
        endAt: shift.end,
        timestamp: moment().format(),
        context: SHIFT_BOOKED_CONTEXT.URGENT_SHIFTS,
        fcfShiftId: targetShiftId,
        ...streaksMetaData,
      });

      history.replace(DeprecatedGlobalAppV1Paths.MY_SHIFTS);
    }
  };

  const hideShiftInfoModal = () => {
    setIsShiftInfoModalOpen(false);
  };

  const showShiftInfoModal = () => {
    setIsShiftInfoModalOpen(true);
  };

  const snoozeNotifications = async () => {
    try {
      setIsUpdatingSnoozePreference(true);
      const response: Response = await setNotificationPreference({
        notificationPreference: {
          key: "urgentShiftNotification",
          snooze: true,
        },
      });

      if (response.code === ResponseCodes.Success) {
        setIsNotificationSnoozed(true);
        setShowTooltip(true);

        setTimeout(() => {
          setShowTooltip(false);
        }, 5000);
      } else {
        showErrorToast(String(response.message));
      }
    } catch (err) {
      // ignore
    }
    setIsUpdatingSnoozePreference(false);
  };

  const undoSnoozeNotifications = async () => {
    try {
      setIsUpdatingSnoozePreference(true);
      const response: Response = await setNotificationPreference({
        notificationPreference: {
          key: "urgentShiftNotification",
          snooze: false,
        },
      });

      if (response.code === ResponseCodes.Success) {
        setIsNotificationSnoozed(false);
        setShowTooltip(false);
      } else {
        showErrorToast(String(response.message));
      }
    } catch {
      // ignore
    }
    setIsUpdatingSnoozePreference(false);
  };

  const getUserNotificationPreferences = async () => {
    try {
      let notificationPreference = worker.notificationPreferences?.urgentShiftNotification ?? {
        isOn: true,
        snoozedOn: null,
        snoozedForDays: null,
      };

      const response: Response = await getNotificationPreference();
      if (response.code === ResponseCodes.Success) {
        notificationPreference = response.data.notificationPreferences?.urgentShiftNotification;
      }

      const { isOn, snoozedOn, snoozedForDays } = notificationPreference;

      const isSnoozed = checkIsNotificationSnoozed(
        isOn,
        snoozedOn ? new Date(snoozedOn) : null,
        snoozedForDays || 1
      );
      setIsNotificationSnoozed(isSnoozed);
    } catch (ex) {
      // Ignore
    }
  };

  const getFcfShiftData = () => {
    if (history.location.search) {
      const searchParams = new URLSearchParams(history.location.search);
      const facilityName = searchParams.get("facilityName");
      const shiftStart = searchParams.get("shiftStart")?.substring(5, 10).replace("-", "/");
      const shiftType = searchParams.get("shiftType");

      if (facilityName && shiftStart && shiftType) {
        setFcfShiftData({ facilityName, shiftStart, shiftType });
      }
    }
  };

  useEffect(() => {
    getUserNotificationPreferences();
    getFcfShiftData();
    // eslint-disable-next-line
  }, [worker.notificationPreferences?.urgentShiftNotification]);

  const urgentShiftIds = sortedShifts
    .filter((shift) => isDefined(shift.shiftId))
    .map((shift) => shift.shiftId ?? "");

  const {
    shiftBookabilityById,
    queryResult: paginatedBookabilityStatusQueryResult,
    bookabilityLoadedShiftsCount: shiftsToRenderCount,
  } = useGetPaginatedBookabilityStatusForShifts({
    shiftIds: urgentShiftIds,
    agentId: worker.userId,
  });

  const {
    isLoading: isLoadingShiftBookability,
    fetchStatus: shiftBookabilityFetchStatus,
    fetchNextPage: fetchNextPageBookabilityStatus,
    isFetchingNextPage: isFetchingShiftBookabilityForNextPage,
    hasNextPage: hasNextOpenShiftPage,
  } = paginatedBookabilityStatusQueryResult;

  return (
    <Fragment>
      <IonPage className="urgent-shifts-page">
        <PageWithHeader
          appBarHeader={
            <AppBarHeader
              title="Urgent Shifts"
              leftCta={<BackButtonLink defaultBackTo={TabRouterPath.OPEN_SHIFTS} />}
            />
          }
          containerVariant="without-margin"
          containerMaxWidth="md"
          onRefresh={async () => {
            return Promise.all([getUserNotificationPreferences(), refetchUrgentShifts()]);
          }}
        >
          <div className="urgent-shifts-filter-toolbar">
            <div style={{ gridColumnStart: "2" }}>
              <Box sx={{ paddingY: 1 }}>
                <ShiftFilters
                  totalShifts={sortedShifts.length}
                  distancePreference={distancePreference}
                  qualificationPreference={qualificationPreference}
                  searchMode={SEARCH_MODE.URGENT_SHIFTS}
                  updateOpenShifts={refetchUrgentShifts}
                  isUrgentShiftFilter={true}
                  setFilters={setFilters}
                  agentLicenses={worker.licensesData}
                />
              </Box>
            </div>
            {sortedShifts.length > 0 && (
              <div className="urgent-shifts-filters">
                <IonButton
                  data-testid="urgent-shift-info-btn"
                  onClick={showShiftInfoModal}
                  fill="clear"
                  className="urgent-shifts__action-button information-icon"
                >
                  <IonIcon icon={informationCircleOutline} slot="icon-only" />
                </IonButton>
                <button
                  data-testid="notification-btn"
                  className="urgent-shifts__action-button notification-icon"
                  disabled={isUpdatingSnoozePreference}
                  onClick={isNotificationSnoozed ? undoSnoozeNotifications : snoozeNotifications}
                >
                  <IonIcon
                    data-testid={isNotificationSnoozed ? "notifications-off" : "notifications"}
                    icon={isNotificationSnoozed ? notificationsOff : notifications}
                    slot="icon-only"
                  />
                  {showTooltip && (
                    <ToolTipCard message="You won’t receive Urgent Shift notifications for the next 24 hours." />
                  )}
                </button>
              </div>
            )}
          </div>

          {environmentConfig.REACT_APP_ENVIRONMENT_NAME !==
            DeploymentEnvironmentName.PRODUCTION && (
            <div style={{ zIndex: 1000 }}>
              <RadarSdkTestPanel />
            </div>
          )}

          {isUrgentShiftsLoading ||
          (isLoadingShiftBookability && shiftBookabilityFetchStatus !== "idle") ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                width="250"
                height="250"
                src="/assets/gifs/loadingAvailableShifts.gif"
                alt="Loading available shifts"
              />
            </div>
          ) : (
            <>
              <ShiftBookingInfoModal
                hideModal={hideShiftInfoModal}
                visible={isShiftInfoModalOpen}
                items={urgentShiftsConfig.infoItems ?? []}
              />

              {sortedShifts.length > 0 ? (
                <>
                  {fcfShiftData?.shiftStart && fcfShiftData?.facilityName && (
                    <h5 className="fcf-message">
                      Replace your {fcfShiftData.shiftStart} {fcfShiftData.shiftType} shift that{" "}
                      {fcfShiftData.facilityName} cancelled
                    </h5>
                  )}
                  <UrgentShiftItems
                    shifts={sortedShifts}
                    updateShiftList={updateShiftList}
                    refreshShifts={refetchUrgentShifts}
                    searchMode={SEARCH_MODE.URGENT_SHIFTS}
                    shiftBookabilityById={shiftBookabilityById}
                    bookabilityCheckQueryResult={{
                      fetchNextPageBookabilityStatus,
                      isFetchingShiftBookabilityForNextPage,
                      isLoadingShiftBookability,
                      hasNextOpenShiftPage,
                      shiftsToRenderCount,
                    }}
                  />
                </>
              ) : (
                <div className="no-urgent-shift-container">
                  <div className="title">
                    <span>
                      No Urgent Shifts right now
                      <IonIcon
                        onClick={showShiftInfoModal}
                        className="icon information-icon"
                        icon={informationCircleOutline}
                      />
                    </span>
                  </div>
                  <div className="subtitle">
                    Sorry, there are no Urgent Shifts available to book at this time. Please check
                    back later.
                  </div>
                </div>
              )}
            </>
          )}
        </PageWithHeader>
      </IonPage>
    </Fragment>
  );
}
